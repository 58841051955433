import { LOAD_ACTION_ATTR_SELECTOR } from './constants';
import { dispatchSnowplowEvent } from './dispatch_snowplow_event';
import getStandardContext from './get_standard_context';
import {
  getEventHandlers,
  createEventPayload,
  renameKey,
} from './utils';

export default class Tracking {
  static queuedEvents = [];
  static initialized = false;

  /**
   * (Legacy) Determines if tracking is enabled at the user level.
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT.
   *
   * @returns {Boolean}
   */
  static trackable() {
    return !['1', 'yes'].includes(
      window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack,
    );
  }

  /**
   * Determines if Tracker is available/enabled.
   *
   * @returns {Boolean}
   */
  static enabled() {
    //return typeof window.snowplow === 'function' && this.trackable();
    return (this.snowplowEnabled() || this.segmentEnabled()) && this.trackable()
  }

  /**
   * Determines if Snowplow is available/enabled.
   *
   * @returns {Boolean}
   */
  static snowplowEnabled() {
    return typeof window.snowplow === 'function'
  }

  /**
   * Determines if Segment is available/enabled.
   *
   * @returns {Boolean}
   */
  static segmentEnabled() {
    return typeof window.analytics === 'object'
  }


  /**
   * Dispatches a structured event per our taxonomy:
   * https://docs.gitlab.com/ee/development/snowplow/index.html#structured-event-taxonomy.
   *
   * If the library is not initialized and events are trying to be
   * dispatched (data-attributes, load-events), they will be added
   * to a queue to be flushed afterwards.
   *
   * @param  {...any} eventData defined event taxonomy
   * @returns {undefined|Boolean}
   */
  static event(...eventData) {
    if (!this.enabled()) {
      return false;
    }

    if (!this.initialized) {
      this.queuedEvents.push(eventData);
      return false;
    }

    return dispatchSnowplowEvent(...eventData);
  }

  /**
   * Dispatches any event emitted before initialization.
   *
   * @returns {undefined}
   */
  static flushPendingEvents() {
    this.initialized = true;

    while (this.queuedEvents.length) {
      dispatchSnowplowEvent(...this.queuedEvents.shift());
    }
  }

  /**
   * Attaches event handlers for data-attributes powered events.
   *
   * @param {String} category - the default category for all events
   * @param {HTMLElement} parent - element containing data-attributes
   * @returns {Array}
   */
  static bindDocument(category = document.body.dataset.page, parent = document) {
    if (!this.enabled() || parent.trackingBound) {
      return [];
    }

    // eslint-disable-next-line no-param-reassign
    parent.trackingBound = true;

    const handlers = getEventHandlers(category, (...args) => this.event(...args));
    handlers.forEach((event) => parent.addEventListener(event.name, event.func));

    return handlers;
  }

  /**
   * Attaches event handlers for load-events (on render).
   *
   * @param {String} category - the default category for all events
   * @param {HTMLElement} parent - element containing event targets
   * @returns {Array}
   */
  static trackLoadEvents(category = document.body.dataset.page, parent = document) {
    if (!this.enabled()) {
      return [];
    }

    const loadEvents = parent.querySelectorAll(LOAD_ACTION_ATTR_SELECTOR);

    loadEvents.forEach((element) => {
      const { action, data } = createEventPayload(element);
      this.event(category, action, data);
    });

    return loadEvents;
  }

  static bindLoadEvents(category = document.body.dataset.page) {
    if (!this.enabled()) {
      return;
    }

    $(LOAD_ACTION_ATTR_SELECTOR).livequery(function() {
      const { action, data } = createEventPayload(this);
      Tracking.event(category, action, data);
    })
  }


  static enableSegmentFormTracking(category = document.body.dataset.page, parent = document) {

    const enabler = () => {
      $('form[data-track-label]').livequery(function() {
        this.addEventListener('submit', (e) => {
          const { data } = createEventPayload(this);
          const fields = []
          this.querySelectorAll('[data-track-label]').forEach((input) => {
            fields.push({name: input.dataset.trackLabel, value: input.value})
          })
          Tracking.event(category, 'submit_form', {...data, ...{extra: {fields: fields}}});
        })
      })
    }

    if (document.readyState === 'complete') {
      enabler();
    } else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
          enabler();
        }
      });
    }
  }
}
