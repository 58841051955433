import { initUserTracking, initDefaultTrackers } from './tracking';
import Tracking from './tracking/tracking'


// Put all initialisations here that can also wait after everything is rendered and ready
function deferredInitialisation() {
  const $body = $('body');

  initDefaultTrackers();
  initUserTracking();
}

requestIdleCallback(deferredInitialisation);
