import '../../assets/javascripts/polyfills/window-polyfill.js'
import 'jquery'
require('@rails/ujs').start()
require('turbolinks').start()
require('trix')
require('@rails/actiontext')
import "../trix-editor-overrides"
import '../../assets/javascripts/livequery/livequery.js'
import 'popper.js'
import 'bootstrap'
import 'owl.carousel'
import '../../assets/javascripts/google_maps_callback.js'
import '../../../vendor/assets/javascripts/jquery.bootstrap.wizard.js'
import '../../../vendor/assets/javascripts/select2-full.js'
import '../../assets/javascripts/select2-cascade.js'
import '../../../vendor/assets/stylesheets/select2.css'
import '../core_static/select2-init.js'
import 'jquery-waypoints/waypoints.min'
import '../core_static/jquery.counterup'
import '../../assets/javascripts/messages'
import '../../assets/javascripts/zendesk.js'
import '../../assets/javascripts/ajax_error'
import '../core_static/gsap.min'
import '../core_static/ScrollTrigger.min'
import 'bootstrap-select'
import '../core_static/lity.min'
import '../core_static/custom'
import '../../assets/javascripts/client_side_validations_patch'
import '../../assets/javascripts/cocoon.js'
import '../core_static/address'
import '../core_static/companies.js'
import '../core_static/company_vendors.js'
import '../core_static/jstree.js'
import '../../assets/stylesheets/core_static/jstree.css'
import '../init-tracking'
import 'intl-tel-input/build/js/intlTelInput'
import 'intl-tel-input/build/js/utils'
import './../lottie-animation'
