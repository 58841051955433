// import { getAllExperimentContexts } from '~/experimentation/utils';
import { DEFAULT_SNOWPLOW_OPTIONS } from './constants';
import getStandardContext from './get_standard_context';
import Tracking from './tracking';

export { Tracking as default };

/**
 * Tracker initialization as defined in:
 * https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/javascript-tracker-v2/tracker-setup/initializing-a-tracker-2/.
 * It also dispatches any event emitted before its execution.
 *
 * @returns {undefined}
 */
export function initUserTracking() {
  if (!Tracking.enabled()) {
    return;
  }

  if (Tracking.snowplowEnabled()) {
    const opts = { ...DEFAULT_SNOWPLOW_OPTIONS, ...window.snowplowOptions };
    window.snowplow('newTracker', opts.namespace, opts.hostname, opts);
    document.dispatchEvent(new Event('SnowplowInitialized'));
  }

  Tracking.flushPendingEvents();
}

/**
 * Enables tracking of built-in events: page views, page pings.
 * Optionally enables form and link tracking (automatically).
 * Attaches event handlers for data-attributes powered events, and
 * load-events (on render).
 *
 * @returns {undefined}
 */
export function initDefaultTrackers() {
  if (!Tracking.enabled()) {
    return;
  }

  const opts = { ...DEFAULT_SNOWPLOW_OPTIONS, ...window.snowplowOptions };

  const standardContext = getStandardContext();
  const experimentContexts = []; //getAllExperimentContexts();

  if (Tracking.snowplowEnabled()) {
    window.snowplow('enableActivityTracking', 30, 30);
    // must be after enableActivityTracking
    window.snowplow('trackPageView', null, [standardContext, ...experimentContexts]);

    if (window.snowplowOptions.linkClickTracking) {
      window.snowplow('enableLinkClickTracking');
    }
  }

  if (Tracking.segmentEnabled()) {
    const onPageLoaded = function(){
      const onAnalyticsReady = function(){
        if (gon.current_user_id && window.analytics.user().id() != gon.current_user_id) {
          window.analytics.identify(gon.current_user_id, {
            name: gon.current_user_name,
            email: gon.current_user_email
          });
        };
        if (gon.current_company_id && window.analytics.group().id() != gon.current_company_id) {
          window.analytics.group(gon.current_company_id, { name: gon.current_company_name });
        };

        window.analytics.page();
      };

      if (window.analytics.initialized) {
        onAnalyticsReady()
      } else {
        window.analytics.ready(onAnalyticsReady)
      }
    };

    document.addEventListener('turbolinks:load', onPageLoaded);
    onPageLoaded();

    if (window.snowplowOptions.formTracking) {
      // Tracking.enableFormTracking(opts.formTrackingConfig);
      Tracking.enableSegmentFormTracking();
    }
    Tracking.bindLoadEvents()
  }

  if (Tracking.snowplowEnabled()) {
    Tracking.trackLoadEvents();
  }
  Tracking.bindDocument();
}
