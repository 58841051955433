import intlTelInput from 'intl-tel-input';

$(document).on("turbolinks:load", function() {
  if (window.zE)
    zE( function () {zE.hide()});
  inputLabelEffect();
  counterNum();
  $("#select_category").selectpicker();
  var currIdx = 0;
  var currLeft = 0;
  var idx = [];
  var val = [];
  var visibleAra = 0;
  var lstAra = 0;
  var allowLeft = 0;
  var lstLft = 0;
  // For google captcha callback
  window.enableSubmit = enableSubmit;
  window.disableSubmit = disableSubmit;

  function enableSubmit(){
    if(document.getElementById("contact-btn")){
      document.getElementById("contact-btn").disabled = false;
    }
  }

  function disableSubmit(){
    if(document.getElementById("contact-btn")){
      document.getElementById("contact-btn").disabled = true;
    }
  }

  //----Phone otp code------
  var user_phone_input = document.querySelector(".user_phone_input")
  if (user_phone_input != null)
  {
    var errorMsg = document.querySelector("#send_otp_msg"),
      validMsg = document.querySelector("#send_otp_msg"),
      errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long"];

    var iti = intlTelInput(user_phone_input, {
      separateDialCode: true,
      hiddenInput: "login",
      utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'
    });

    var reset = function() {
      user_phone_input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide")
      validMsg.classList.add("hide")
    };

    user_phone_input.addEventListener('change', reset)
    user_phone_input.addEventListener('keyup', reset)

    $('#btn_users_send_otp').on('click', function() {
      $('#send_otp_msg').text('')
      $('#send_otp_msg').css("display", "")
      if (user_phone_input.value.length > 0)
      {
        reset();
        if (user_phone_input.value.trim()) {
          if (iti.isValidNumber()) {
            var iti_full_phone_no = iti.getNumber(intlTelInputUtils.numberFormat.E164)
            $(this).css("disabled", true)
            $(this).text("Sending Pin ...")
            validMsg.classList.remove("hide");
            $.post($(this).attr('data-href'), {'phone_number': iti_full_phone_no, 'pin_page': $(this).attr('data-pin-page') }, function(result){
                $("div.user_verification_pin").css("display","block")
                $('#send_otp_msg').text(result["success"])
            },'json')
            .fail(function(errors) {
              $('#send_otp_msg').text(errors.responseJSON['error'])
            })
            $('#btn_users_send_otp').text("Resend Pin")
            $('#send_otp_msg').fadeOut(9000).next().fadeIn()
          } else {
            user_phone_input.classList.add("error")
            var errorCode = iti.getValidationError()
            if (errorCode in errorMap){
              errorMsg.innerHTML = errorMap[errorCode]
            }
            else{
              errorMsg.innerHTML = errorMap[0]
            }
            errorMsg.classList.remove("hide")
          }
        }
      }
    })
  }
  //----End of phone otp code------

  $('.read-more-text').on('click', function () {
    $(".hide-text").toggle(500);
    $(this).toggleClass('read-less-text');
  });


  $('.nav-arrow.nav-arrow-right').on('click', function () {
    if (idx.length == 0) {
      visibleAra = $('.nav-tab-sec').width();
      lstAra = $('.nav.nav-pills').width();
      allowLeft = lstAra - visibleAra;
      $.each($('.nav.nav-pills a.nav-pill-link'), function (i, el) {
        idx.push(i);
        val.push($(el).outerWidth() + 1);
      });
    }

    if (lstLft == 0) {
      if ((currLeft + val[currIdx]) > allowLeft) {
        lstLft = lstAra - (currLeft + visibleAra);
        $('#v-pills-tab').css("margin-left", -(currLeft + lstLft));
        currIdx++;
        currLeft += lstLft;
      }
      else {
        currLeft += val[currIdx];
        $('#v-pills-tab').css("margin-left", -currLeft);
        currIdx++;
      }
    }
  });
  stick_search_category()
  $('.nav-arrow.nav-arrow-left').on('click', function () {
    if (idx.length == 0) {
      visibleAra = $('.nav-tab-sec').width();
      lstAra = $('.nav.nav-pills').width();
      allowLeft = lstAra - visibleAra;
      $.each($('.nav.nav-pills a.nav-pill-link'), function (i, el) {
        idx.push(i);
        val.push($(el).outerWidth());
      });
    }

    if (lstLft > 0 && currIdx > 0) {
      currLeft -= lstLft;
      lstLft = 0;
      $('#v-pills-tab').css("margin-left", -currLeft);
      currIdx--;
    }
    else if (currIdx == 1) {
      $('#v-pills-tab').css("margin-left", 0);
      currIdx--;
      currLeft = 0;
    }
    else if (currIdx > 0){
      currIdx--;
      currLeft -= val[currIdx];
      $('#v-pills-tab').css("margin-left", -currLeft);
    }
  });

  $(document).on('click', '.comman-footer2-ul .link-right-sweep', function () {
    var da = $(this).attr('data-tab-target');
    $('.nav.nav-pills a[data-tab="' + da + '"]').trigger('click');
    $('html, body').animate({
      scrollTop: $("html, body").offset().top
    });
  });

  $('.expand-carousel').owlCarousel({
    dots: false, nav: false, margin: 12, center: true, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    navText: ["<i class='material-icons'>chevron_left</i>", "<i class='material-icons'>chevron_right</i>"],
    responsive: {
      0: { items: 2, nav: true, margin: 5, center: false },
      575: { items: 2, nav: true, margin: 5, center: false },
      768: { items: 3, nav: true, margin: 5, center: false },
      992: { items: 4 },
      1200: { items: 4 }
    }
  });

  $('.owl-blog').owlCarousel({
    dots: false, nav: true, margin: 15, center: false, autoplay: false, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    navText: ["<i class='material-icons'>chevron_left</i>", "<i class='material-icons'>chevron_right</i>"],
    responsive: {
      0: { items: 1 },
      575: { items: 1 },
      768: { items: 1 },
      992: { items: 1 },
      1200: { items: 1 }
    }
  });

  $('.owl-service-list').owlCarousel({
    dots: false, nav: true, margin: 0, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    navText: ["<i class='material-icons'>chevron_left</i>", "<i class='material-icons'>chevron_right</i>"],
    responsive: {
      0: {
        items: 1,
      }
      , 575: {
        items: 1
      }
      , 768: {
        items: 2,
      }
      , 992: {
        items: 3
      }
      , 1200: {
        items: 3
      }
    }
  });

  $('.owl-empower').owlCarousel({
    dots: true, nav: false, margin: 20, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      }
      , 575: {
        items: 2
      }
      , 768: {
        items: 3,
      }
      , 992: {
        items: 4
      }
      , 1200: {
        items: 5
      }
    }
  });

  $('.owl-clients').owlCarousel({
    dots: false, nav: false, margin: 20, center: true, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    responsive: {
      0: {
        items: 1,
        center: false
      }
      , 575: {
        items: 2,
        center: false
      }
      , 768: {
        items: 3,
      }
      , 992: {
        items: 5
      }
      , 1200: {
        items: 7,
      }
    }
  });

  $('.owl-logos').owlCarousel({
    dots: false, nav: false, margin: 20, center: true, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,
    responsive: {
      0: {
        items: 3,
        center: false
      }
      , 575: {
        items: 2,
        center: false
      }
      , 768: {
        items: 3,
      }
      , 992: {
        items: 5
      }
      , 1200: {
        items: 7,
      }
    }
  });

  $('.owl-comman-1').owlCarousel({
   dots: true, nav: false, margin: 10, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true, autoplayTimeout:30000,
    responsive: {
     0: {
       items: 1
     }
     , 575: {
       items: 1
     }
     , 768: {
       items: 1
     }
     , 992: {
       items: 1
     }
     , 1200: {
       items: 1
     }
   }
  });
  $('.owl-comman-2').owlCarousel({
    dots: true, nav: false, margin: 10, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: false, lazyLoad: true,  items: 2,
    responsive: {
      0: {
        items: 1,
        dots: true
      },
      400: {
        items: 1,
        dots: true
      }
      , 575: {
        items: 1,
        dots: true
      }
      , 768: {
        items: 1,
        dots: true
      }
      , 992: {
        items: 2,
        dots: true
      }
      , 1200: {
        items: 2
      }
    }
  });
  $('.owl-comman-4').owlCarousel({
    dots: false, nav: false, margin: 12, center: false, autoplay: false, mouseDrag: true, touchDrag: true, loop: true, autoWidth: true, lazyLoad: true,
     responsive: {
      0: {
        items: 1,
        autoWidth: false,
      }
      , 575: {
        items: 2,
        autoWidth: false,
      }
      , 768: {
        items: 3,
      }
      , 992: {
        items: 4
      }
      , 1200: {
        items: 4
      }
    }
  });

  $('.app-details-carousel').owlCarousel({
      items: 3,
      stagePadding: 0,
      margin: 10,
      touchDrag: true,
      mouseDrag: true,
      dots: true,
      loop: false,
      nav: false,
      responsive: {
          0: {
              items: 1
          },
          576: {
              items: 2
          },
          1199: {
              items: 3
          }
      }
  });
  if ($(window).width() > 991 && $(window).width() < 1800) {
    // stick categories at top
    ScrollTrigger.create({
      trigger: "#stick-it3",
      start: "top top+=62",
      endTrigger: ".end-sticky3",
      end: "bottom bottom-=20%",
      pin: true,
      pinSpacing: false,
      scrub: 4,
      markers: false,
      toggleClass: { targets: ".sticky-it2", className: "is-sticky" }
    });
  }

  $('.owl-auto-width').owlCarousel({
    dots: false, nav: false, margin: 0, center: false, autoplay: true, mouseDrag: true, touchDrag: true, loop: true, autoWidth: true, lazyLoad: true, items: 4, autoplayTimeout:5000,
  });

  var wid2 = $(window).width();

  if (wid2 <= 767) {
    $(document).on("click", ".nav-link", function () {
      $(this).closest(".nav-item").addClass('open');
    });
    $(document).on("click", ".back-menu", function () {
      $(this).closest(".nav-item").removeClass('open');
    });

    $(document).on("click", ".nav-toggle", function () {
      if($(".nav-toggle.nav-toggle-xs span").hasClass("d-none")){
        $(".nav-toggle.nav-toggle-xs span").removeClass('d-none');
        $(".nav-toggle.nav-toggle-xs .msite-close").addClass('d-none');
      }else{
        $(".nav-toggle.nav-toggle-xs span").addClass('d-none');
        $(".nav-toggle.nav-toggle-xs .msite-close").removeClass('d-none');
      }
    });

    $('.filter-icon-sec').click(function(){
      $(this).toggleClass('filter-close');
      $(this).closest(".job-requirment-sec").find('.filter-content').slideToggle();
    });
  }
  $(document).on("click", ".clint-info-blck", function () {
    $(".clint-info-blck.active, .client-info-content-blck.active").removeClass("active");
    $(this).addClass('active');
    var dt = $(this).attr("data-type");
    $('.client-info-content-blck.' + dt).addClass('active');
  });
  $('.view-position-btn').on('click', function (e) {
    $('html, body').animate({
      scrollTop: $(".department-section").offset().top - 30
    }, 2000);
  });

  $('#jd-office').change(function(){
    toggleJobPostings()
  })
  $('#jd-department').change(function(){
    toggleJobPostings()
  })

  function toggleJobPostings(){
    var $section = $('#job_search_by_location_dept')
    var $card = $section.find('.card').removeClass('hide')
    var $msg = $section.find('#no-jobs-message').addClass('hide')
    $card.each(function(){
      var $cardBody = $(this).find('.card-body')
      var departmentId = parseInt($("#jd-department").val())
      var officeId = parseInt($("#jd-office").val())
      $cardBody.removeClass('hide')
      $cardBody.each(function(){
        if(!(!departmentId || $(this).data('department-ids').indexOf(departmentId) >= 0))
          $(this).addClass('hide');
        if(!(!officeId || $(this).data('office-ids').indexOf(officeId) >= 0))
          $(this).addClass('hide');
      })
      if(!$cardBody.not('.hide').length)
        $(this).addClass('hide');
    });
    if(!$card.not('.hide').length)
      $msg.removeClass('hide')
  }


  $('#case-study-categories').change(function(){
    view_case_studies($(this).val(), $('#case-study-trades').val())
  })
  $('#case-study-trades').change(function(){
    view_case_studies($('#case-study-categories').val(), $(this).val())
  })

  $(".check_app_store_category").click(function(){
    var cat_values = []
    for (var i = 1; i <= 11; i++) {
      if ($("#cat_"+i).is(":checked")){
        cat_values.push($("#cat_"+i).val())
      }
    }
    $.get('/web_app_stores', {categories: cat_values}, function succ(){} , "script")
  });

  $("#select_category").change(function(){
    $.get('/web_app_stores', {categories: $(this).val()}, function succ(){} , "script")
  })

  $('#app_search').on('input', function() {
    $.get('/web_app_stores', {search_term: $(this).val()}, function succ(){} , "script")
  })

  $(".bttn-app-toggle").click(function () {
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
        $('.app-categories-ul .checkbox_li input').prop('checked', true);
    } else {
        $('.app-categories-ul .checkbox_li input').prop('checked', false);
    }
    cat_values = []
    for (var i = 1; i <= 11; i++) {
      if ($("#cat_"+i).is(":checked")){
        cat_values.push($("#cat_"+i).val())
      }
    }
    $.get('/web_app_stores', {categories: cat_values}, function succ(){} , "script")
  });

});

var inputLabelEffect = function () {
  $(".input-effect .input-control-wrap").not("#email_id").val("");
  // $(".input-effect .input-control-wrap").has('option:selected[value!=""]').val("");
  $(".input-effect .input-control-wrap").focusout(function () {
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });

  if($('.pr-form-control .input-control-wrap').val() != '' ) {
    $(this).addClass("has-content");
  }
  $('.pr-form-control .input-control-wrap').blur(function(){
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });

};

function counterNum(){
  $('.counter').counterUp({ delay: 10, time: 1000});
}

$(document).on('click', '.other-nav-item', function () {
  $(this).toggleClass('active');
});

function validateBookCallAndContactFm(){
  var email = String(document.getElementById("email_id").value).toLowerCase();
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (document.getElementById("full_name").value == ""){
    alert("Name can't be blank"); return false;
  }
  if (document.getElementById("phone_num").value == ""){
    alert("Phone Number can't be blank"); return false;
  }
  if (email == ""){
    alert("Email can't be blank"); return false;
  }
  if (!re.test(email)){
    alert("Please enter a valid email address");return false;
  }
  if (document.getElementById("company_name").value == ""){
    alert("Company Name can't be blank"); return false;
  }
  if (!document.getElementById("g-recaptcha-response") || document.getElementById("g-recaptcha-response").value == ""){
    alert("Please check the captcha box"); return false;
  }

  return true;
}

function getCalendlyUrl(){
  var url = "https://calendly.com/benny-jones/30min"
  Calendly.initPopupWidget({url: url});
}

function postHubSpot(){
  // Fileds are tightly coupled with hubspot forms -> ref. production https://app.hubspot.com/forms/9065694 else https://app.hubspot.com/forms/9065694, Ref. https://utilizecore.atlassian.net/browse/UCW-3439?focusedCommentId=26060
  // Do not change value of key name else need to change on hubspot for same form also.
  // hb_page is also tightly coupled with WebContactForm to post form on HubSpot
    var payload = {
      "web_contact_forms": {
        "name": document.getElementById("full_name").value,
        "phone": document.getElementById("phone_num").value,
        "email": document.getElementById("email_id").value,
        "company_name": document.getElementById("company_name").value,
        "service_type": document.getElementById('company_service').value,
        "industry": document.getElementById('industry').value,
        "state": document.getElementById('state').value,
        "comment": (document.getElementById('comment') && document.getElementById('comment').value),
        "contact_type": (document.getElementById("hb_page") && document.getElementById("hb_page").value)
      },
      "g-recaptcha-response": (document.getElementById("g-recaptcha-response") && document.getElementById("g-recaptcha-response").value)
    }

    $.ajax({
      type: "POST",
      url: '/web_contact_forms',
      data: payload,
      success: function(data, status){
        if(document.getElementById("hb_page") && document.getElementById("hb_page").value == 'contact_us'){
          resetBookCallAndContactFm();
        }
      },
      error: function(jqXhr, status, err){}
    });
}

$(document).on('click', '#calendlyButton', function () {
  getCalendlyUrl();return false;
});

$(document).on('click', '#contact-btn', function () {
  if(validateBookCallAndContactFm()){
    disableSubmit();
    postHubSpot();
  }
});

function resetBookCallAndContactFm(){
  ['full_name', 'email_id', 'phone_num', 'company_name', 'industry', 'state', 'comment'].
    forEach(function(sel) {
      if(document.getElementById(sel)){
        document.getElementById(sel).value = "";
      }
  })
}

$(document).on('submit', 'form.pre-book-call', function(){
  var email = String(document.getElementById("email").value).toLowerCase();
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email == ""){
    alert("Please enter an email address.");return false;
  } else if (!re.test(email)){
    alert("Please enter a valid email address.");return false;
  }
  return true;
});

$(document).on('turbolinks:before-cache', function() {
  $(".owl-carousel").owlCarousel('destroy');
});

$(document).on('change', '.image-upload', function (event) {
  var client_image = $(this).hasClass('client-image')
  var files = event.target.files
  var image = files[0]
  var reader = new FileReader
  if (image.type.match(/image\/(jpg|jpeg|pjpeg|png|x-png|gif)/i)) {
    reader.onload = function(file) {
      var element, img;
      img = new Image;
      element = $(event.target).parents('.image-wrapper').find('.image-container');
      element.html($('<img>').attr({
        src: file.target.result,
        "class": 'img-preview img-responsive'
      }));
    };
    return reader.readAsDataURL(image);
  } else {
    $(this).val(null);
    alert("Supported File Formats Are: jpg, jpeg, png, gif"); return false;
  }
});

$(document).on('click', '.showHide', function () {
  var field = $(this).parent().parent().find('#user_password');
  if (field.attr("type") == "password") {
    field.attr("type", "text");
    $(this).find(".fa").removeClass("fa-eye-slash").addClass("fa-eye");
  } else {
    field.attr("type", "password");
    $(this).find(".fa").removeClass("fa-eye").addClass("fa-eye-slash");
  }
});

function view_case_studies(cat_id, trade_id){
  Turbolinks.visit("?category=" + cat_id + "&trade=" + trade_id)
}

$(function () {
  $(".dropdown.hover-dropdown").hover(function () {
      $('.dropdown-menu', this).stop(true, true).fadeIn("fast");
      $(this).toggleClass('open');
  }, function () {
      $('.dropdown-menu', this).stop(true, true).fadeOut("fast");
      $(this).toggleClass('open');
  });
});

function stick_search_category(){
  // $(".lity-trigger").on('click', '[data-my-lightbox]', lity);

  if ($(window).width() < 992) {
    // stick search at top
    ScrollTrigger.create({
      trigger: ".stick-it",
      start: "top top+=55",
      endTrigger: ".end-sticky",
      end: "bottom top+=100",
      pin: true,
      pinSpacing: false,
      scrub: 4,
      markers: false,
      toggleClass: { targets: ".has-custom-search", className: "is-sticky" }
    }),
    // stick categories at top
    ScrollTrigger.create({
      trigger: ".stick-it2",
      start: "top top+=55",
      endTrigger: ".end-sticky2",
      end: "bottom-=20 bottom-=20%",
      pin: true,
      pinSpacing: false,
      scrub: 4,
      markers: false,
      toggleClass: { targets: ".sticky-it2", className: "is-sticky" }
    });
  } else {
    // stick search at top
    ScrollTrigger.create({
      trigger: ".stick-it",
      start: "top top+=55",
      endTrigger: ".end-sticky",
      end: "bottom top+=100",
      pin: true,
      pinSpacing: false,
      scrub: 4,
      markers: false,
      toggleClass: { targets: ".has-custom-search", className: "is-sticky" }
    }),
    // stick categories at top
    ScrollTrigger.create({
      trigger: ".stick-it2",
      start: "top top+=55",
      endTrigger: ".end-sticky2",
      end: "bottom-=20 bottom-=20%",
      pin: true,
      pinSpacing: false,
      scrub: 4,
      markers: false,
      toggleClass: { targets: ".sticky-it2", className: "is-sticky" }
    });
  }

  $(".dispose-cat-labels").on('click', function () {
    $(this).parents('.cat-labels').remove();
  });
  $(".clear-cat-labels").on('click', function () {
    $('.cat-labels').remove();
  });
}

$(document).ready(function () {
  if ($(window).width() > 991) {
    $('.comman-ul2 li a[href*="#"]').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($(".scroll-here")).offset().top - 80,
        }, 420, 'linear');
    });
  }
});


$(window).on('popstate', function(event) {
  setTimeout(function() {
    if ($('.help-me div[id^="hbspt-form-"]').length > 0) {
      $('div[id^="hbspt-form-"]').filter(
        function(idx) {
          if ($('div[id^="hbspt-form-"]').length > 1 && idx != 0) {
            $('#' + this.id).remove();
          }
        }
      );
    }
  }, 100);
});

$(document).on('keyup', '.company-user-creation-wizard .legal-company-name', function() {
  if($(this).val() == '') {
    $('.image-container-initial').addClass('hide')
    $('.static-company-logo').removeClass('hide')
  }else{
    $('.image-container-initial').removeClass('hide')
    $('.static-company-logo').addClass('hide')
  }
  var initial = ''
  $(this).val().split(' ').slice(0, 2).forEach(function(item) {
    initial += item.charAt(0).toUpperCase()
  })
  $('.company-name-initial').text(initial)
})

  