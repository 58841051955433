//= require ./selector-set
//= require ./selector-observer
//= require ./../polyfills/intersection-observer-polyfill

function isAnyPartOfElementInViewport(el, checkVisibility) {
  const rect = el.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  if(checkVisibility)
    return (vertInView && horInView && rect.bottom > 0 && rect.right > 0);
  else
    return (vertInView && horInView);
}

function isAnyPartOfElementIsVisible(el) {
  return isAnyPartOfElementInViewport(el, true)
}

(function(win, $){
  if (typeof SelectorObserver === 'undefined') {
    var documentObserver = require('./selector-observer').getDocumentObserver(window.document)
  } else {
    var documentObserver = new SelectorObserver.default(window.document);
  }


  //eventType = visible/enter/exit/enterOnce/exitOnce/undefined
  $.fn.livequery = function(eventType, fn, fn2) {
    var selector = this.selector, onAdd, onRemove;

    if ($.isFunction(eventType)){
			fn2 = fn, fn = eventType, eventType = undefined;
      onAdd = function(el) {
        try {
          fn.apply(el)
        } catch(err) {
          console.error(err)
        }
      }
    } else {
      eventType = eventType.split(':')
      onAdd = function(el){
        try {
          var intersectionObservercallback = function(entries, observer){
            for(var i = entries.length; i--; ){
              var entry = entries[i], shouldExecute = false;
              if(entry.intersectionRatio > 0)
                if(eventType[0] == 'visible' && isAnyPartOfElementIsVisible(entry.target))
                  shouldExecute = true
                else if(eventType[0] == 'enter')
                  shouldExecute = true
              if(shouldExecute){
                fn.apply(entry.target)
                if(eventType[1] && eventType[1] == 'once') {
                  observer.unobserve(entry.target)
                }
              }
            }
          }
          var observer = new IntersectionObserver(intersectionObservercallback, {rootMargin: $(selector).data('rootMargin'), root: document.querySelector($(selector).data('root'))})
          observer.observe(el)
        } catch(err) {
          console.error(err)
        }
      }
    }
    if(fn2)
      onRemove = function(el) {fn2.apply(el)}
    documentObserver.observe(selector, {add: onAdd, remove: onRemove})
  }
})(window, jQuery)
