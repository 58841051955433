import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form'

var pr_form_control_wrapper = {
  pr_form_control_wrapper: {
    add (element, settings, message) {
      const wrapper = element.closest(settings.wrapper_tag + '.' + settings.wrapper_class.replace(/ /g, '.'))
      let errorElement = wrapper.find(settings.error_tag + '.' + settings.error_class.replace(/ /g, '.'))

      if (!errorElement.length) {
        errorElement = $('<' + settings.error_tag + '>', { class: settings.error_class, text: message })
        wrapper.append(errorElement)
      }

      wrapper.addClass(settings.wrapper_error_class)
      return errorElement.text(message)
    },

    remove (element, settings) {
      const wrapper = element.closest(settings.wrapper_tag + '.' + settings.wrapper_class.replace(/ /g, '.') + '.' + settings.wrapper_error_class)
      const errorElement = wrapper.find(settings.error_tag + '.' + settings.error_class.replace(/ /g, '.'))

      wrapper.removeClass(settings.wrapper_error_class)
      return errorElement.remove()
    }
  }
}

$.extend(ClientSideValidations.formBuilders['SimpleForm::FormBuilder'].wrappers, pr_form_control_wrapper)

$(document).on('focusout', 'form#new_user input', function() {
  if ($(this).val() == '' || $(this).val() == undefined) {
    ClientSideValidations.formBuilders['SimpleForm::FormBuilder'].add($(this), $(this).parents('form').data('clientSideValidations').html_settings, "Can't be blank")
  }
});

$(document).on('change', "form#new_user input[id$='_terms_and_conditions']", function() {
  if ($(this).prop('checked') == true) {
    $(this).parents('form').find(':submit').prop('disabled', false)
  } else {
    $(this).parents('form').find(':submit').prop('disabled', true)
  }
});

