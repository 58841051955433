$(document).ready(function() {
  $(document).on('keyup', 'input#vendor-selection', function(e) {
    e.preventDefault();
    var paramsData = $(this).val().trim();
    if(paramsData) {
      $.ajax({
        url: "/company/company_vendors/get_companies",
        type: 'GET',
        data: {q: {name_or_phone_or_legal_address_address1_or_legal_address_zip_code_or_email_cont_any: paramsData}, per_page: 50, page: 1}
      });
    } else {
      setTimeout(function(){
        $('.form-banner-section #selected-company-list .all-company').html("");
      }, 1000);
    }
  });

  $(document).on('change', '.send_platform_invite:checkbox', function(e) {
    if ($(this).prop('checked')) {
      $("#next_nav").text('Next: Application Forms');
    } else {
      $("#next_nav").text('Finish');
    }
  });

  $('form.vendor_uc').on('submit', function(){
    if ($('#company_legal_address_attributes_formatted_address').length) {
      var legal_address_dom = $('#company_legal_address_attributes_formatted_address'),
        address_dom = $('#company_legal_address_attributes_address1'),
        city_dom = $('#company_legal_address_attributes_city'),
        state_dom = $('#company_legal_address_attributes_state'),
        span_dom = "<span class='help-block'>can't be blank</span>";

      var legal_address = (legal_address_dom.val() || '').trim(),
        address = (address_dom.val() || '').trim(),
        city = (city_dom.val() || '').trim(),
        state = (state_dom.val() || '').trim(),
        is_valid = true;

      legal_address_dom.next('span').remove('span');
      address_dom.next('span').remove('span');
      city_dom.next('span').remove('span');
      state_dom.next('span').remove('span');

      if (!legal_address) {
        legal_address_dom.after(span_dom);
        is_valid = false;
      }

      if (!address) {
        address_dom.after(span_dom);
        is_valid = false;
      }

      if (!city) {
        city_dom.after(span_dom);
        is_valid = false;
      }

      if (!state) {
        state_dom.after(span_dom);
        is_valid = false;
      }

      if (is_valid) {
        $("#address_inputs").collapse("hide");
        return true;
      } else {
        $("#address_inputs").collapse("show");
        return false;
      }
    }
    return true;
  });

});
