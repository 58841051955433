$(document).on('shown.bs.tab', 'a[data-toggle=tab]', function() {
  initializeSelect2($('.location-wizard .select2'))
})

$(document).on('keyup', 'a[data-toggle=tab]', function(e) {
  initializeSelect2($('.location-wizard .select2'))
});

$(document).on('click', 'select.select2-input', function(e) {
  initializeSelect2($(this))
});

stringToObj = function(path, value, obj) {
  var parts = path.split("."),
    part;
  var last = parts.pop();
  while (part = parts.shift()) {
    if (typeof obj[part] != "object") obj[part] = {};
    obj = obj[part]; // update "pointer"
  }
  obj[last] = value;
}

enableSelect2option = function(parentSelector, changed) {
  $(parentSelector).data('select2cascade').forEach(function(el) {
    var selectedValue = $(parentSelector).val()
    var url = el.url
    var selector = el.selector
    var select2Options = $(selector).data('select2Options') || {}
    var defaultSelected = $(selector).data('defaultSelected')
    var parentsValues = []
    if ($(parentSelector).data('cascade-parent-values'))
      parentsValues = $(parentSelector).data('cascade-parent-values').slice()
    parentsValues.push($(parentSelector).val())
    $(selector).data('cascade-parent-values', parentsValues)
    if (url)
      parentsValues.forEach(function(value, index) {
        url = url.replaceAll('parent-id-' + (index + 1), value);
      })
    url = url.replaceAll('parent-id', $(parentSelector).val());
    if (el.parentRefParam) {
      stringToObj(el.parentRefParam, $(parentSelector).val(), obj = {})
      var combinator = url.indexOf('?') == -1 ? '?' : '&'
      url += combinator + $.param(obj)
    }
    $(selector).data('select2Options', $.extend(select2Options, {
      ajax: {
        url: url
      }
    }))
    if (changed) {
      $(selector).val(null).trigger('change');
    }
    if (defaultSelected) {
      $(selector).val(defaultSelected).trigger('change');
    }
    if ($(selector).data('select2')) {
      $(selector).select2('destroy')
    }
    $(selector).attr('disabled', false)
    initializeSelect2($(selector))
  })
}

disbleSelect2option = function(selector) {
  $(selector).data('select2cascade').forEach(function(el) {
    $(el.selector).attr('disabled', 'disabled')
  })
}

initializeSelect2 = function(selector) {
  var options = {
    width: '100%'
  }
  $.extend(options, $(selector).data('select2Options'))
  var mappings = $(selector).data('select2Mappings') || {}
  options.placeholder = $(selector).attr('placeholder')
  options.allowClear = $(selector).attr('allowClear')
  if (options.dropdownParent) {
    options.dropdownParent = $(selector).closest(options.dropdownParent)
  }
  if (options.ajax) {
    options.ajax.delay = 250
    options.ajax.data = function(params) {
      data = {
        page: params.page || 1,
        with_non_existing_clients: options.ajax.with_non_existing_clients || false
      }
      if (mappings.searchParam) {
        mappings.searchParam.split(',').forEach(function(el) {
          var parts = el.split('=')
          if (parts.length == 2) {
            data[parts[0]] = parts[1]
          } else {
            data[parts[0]] = params.term
          }
        });
      } else {
        data['q'] = params.term
      }
      return data
    }
    options.ajax.processResults = function(data, params) {
      var results = data[(mappings.responseSelector || 'result')]
      if (mappings.group) {
        var group = {}
        $.each(results, function(index, el) {
          var value = el[mappings.group.key]
          var groupName = mappings.group.mapping[value]
          if (!group[groupName])
            group[groupName] = {
              text: groupName,
              children: []
            }
          group[groupName].children.push(el)
        })
        results = []
        var count = 0
        $.each(group, function(name, object) {
          results.push(object)
          count++
        })
        results = count > 1 ? results : (results[0].children || [])
      }
      params.page = params.page || 1;
      if (mappings.defaultOptions) {
        mappings.defaultOptions.forEach(function(el) {
          results.unshift(el)
        })
      }
      if (mappings.idSelector) {
        results.forEach(function(r) {
          r.org_id = r.id
          r.id = mappings.idSelector.split('.').reduce(getIdSelectorVal, r)
        })
      }
      if (mappings.textSelector) {
        results.forEach(function(r) {
          r.text = mappings.textSelector.split('.').reduce(getIdSelectorVal, r)
        })
      }
      result = {
        results: results,
        pagination: {
          more: data.meta.next_page !== null
        }
      };
      return result;
    }
  }
  if (options.tagsRegexp) {
    options.createTag = function(params) {
      var tagsRegexp = new RegExp(options.tagsRegexp.source, options.tagsRegexp.options)
      var term = $.trim(params.term);
      if (!tagsRegexp.test(term)) {
        return null;
      }

      return {
        id: term,
        text: term,
        newTag: true // add additional parameters
      }
    }
  }
  if (options.templateResult) {
    options.templateResult = eval(options.templateResult)
  }
  if (mappings.textSelector) {
    if (options.templateSelection) {
      options.templateSelection = eval(options.templateSelection)
      options.templateResult = function(result) {
        return result.text = result[mappings.textSelector] || result.text;
      }
    } else {
      options.templateSelection = function(result) {
        return result.text = result[mappings.textSelector] || result.text;
      }
    }
    options.templateResult = options.templateResult || options.templateSelection
  } else if (options.templateResult) {
    options.templateSelection = options.templateResult
  }
  $(selector).select2(options)
  var cascadeData = $(selector).data('select2cascade')
  if (cascadeData) {
    if (!Array.isArray(cascadeData)) {
      $(selector).data('select2cascade', [cascadeData])
    }
    if ($(selector).val() == '' || $(selector).val() == null) {
      disbleSelect2option(selector)
    } else {
      enableSelect2option(selector)
    }
    $(selector).on('change', function(e) {
      if ($(selector).val() != null && $(selector).val().length) {
        enableSelect2option(selector, true)
      } else {
        disbleSelect2option(selector)
      }
    })
  }
}

function select2companyTemplateResult(data) {
  if (data.id && data.subtext != '') {
    return $("<strong class='fs14'>" + data.name + "<br/><span class='fs12'>" + data.subtext + "</span>")
  } else {
    return data.name
  }
}

function select2woStatusTemplateResult(data) {
  return $("<span> <i class='fa " + data.icon + "' style='color:" + data.color + ";'></i></span><span class='text-capitalize fs14 pl5'>" + data.name + "<span/>")
}

getIdSelectorVal = function(element, selector) {
  return element[selector];
}
