window.addEventListener('DOMContentLoaded', () => {
  $('select.select2-unique-input').select2()
  if (!($(this).hasClass("select2-hidden-accessible"))) {
    initializeSelect2('select.select2-unique-input');
  }
})

$(document).ready(function() {
  $(document).on('change', '.new-company-selection', function(e) {
    var company_id;
    e.preventDefault();
    if (company_id = $(this).val()) {
      window.location.href = "/companies/" + company_id;
    }
  });
});

function clientSideValidatableForms(target) {
  return $(target).is('form[data-client-side-validations]') ? [$(target)] : $(target).find('form[data-client-side-validations]')
}

function enableClientSideValidations(target){
  $.each(clientSideValidatableForms(target), function(i, form){
    $(form).enableClientSideValidations()
  })
}

initilizeBootstrapWizard = function(data) {
  var form, wizard;
  wizard = data;
  form = $(wizard).parents('form:first');
  $(wizard).bootstrapWizard({
    onInit: function() {
      var currentIndex;
      currentIndex = $(wizard).data('current-tab-index');
      if ((currentIndex != null) && currentIndex !== 0) {
        setTimeout((function() {
          $(wizard).bootstrapWizard('show', currentIndex);
        }), 1);
      }
    },
    onTabShow: function() {
      $(form).enableClientSideValidations();
    },
    onNext: function(tab, navigation, index) {
      if ($(form)[0].ClientSideValidations) {
        if (!$(form).isValid($(form)[0].ClientSideValidations.settings.validators)) {
          false;
        }
      }
    }
  });
}

$(document).on('click', '#new-company-wizard a.next', function(e) {
  e.preventDefault();
  if ($(".main-company-form").isValid($(".main-company-form")[0].ClientSideValidations.settings.validators)){
    $('#new-company-wizard').bootstrapWizard('next');
  }
});

$(document).on('click', '#new-company-wizard a.previous', function(e) {
  e.preventDefault();
  $('#new-company-wizard').bootstrapWizard('previous');
});


initilizeProfileRegions = function() {
  $('#profile_regions').jstree({
    'core' : {
      'data' : {
        'url' : function (node) {
          regionId = ($('#profile_regions').data('region_id') || '')
          if (node.id == "#") {
            return "/company/wizard/profile/states?region_id="+regionId
          } else if (node.data.resource_type == "State") {
            return "/company/wizard/profile/counties?state_id="+node.data.resource_id+"&region_id="+regionId
          } else if (node.data.resource_type == "County") {
            return "/company/wizard/profile/zip_codes?county_id="+node.data.resource_id+"&region_id="+regionId
          }
        },
        'dataType' : "json",
        'data' : function (node) {
          return { "id" : node.id };
        }
      }
    },
    "checkbox" : {
      "keep_selected_style" : true
    },
    "plugins" : [ "checkbox" ]
  });
}

initilizePageContentWrapper = function() {
  $('#page-content-wrapper').css('padding-left',$('#mySidenav').width() == null ? 0 : $('#mySidenav').width());

  $('#mySidenav').find('.active').closest('.dropdown').addClass('open');
  $(document).click(function(){
    $('.active.dropdown').addClass('open');
  });

  $(document).on("click", "#dropdownMenuButton", function(){
    $('.active.dropdown').addClass('open');
  });
}

$(document).ready(function() {
  $(document).on('click', 'form#profile_region button.profile_region_submit_link', function(e) {
    e.preventDefault();
    stateIds    = []
    countyIds   = []
    zipCodeIds  = []
    $('form#profile_region').find('#profile_regions').jstree().get_top_checked(true).forEach(function(node) {
      if (node.data.resource_type == 'ZipCode') {
        zipCodeIds.push(node.data.resource_id)
      } else if (node.data.resource_type == 'County') {
        countyIds.push(node.data.resource_id)
      } else if (node.data.resource_type == 'State') {
        stateIds.push(node.data.resource_id)
      }
    });
    params = {}
    params.region = {}
    params.region.name = $('form#profile_region').find('input#region_name').val()
    params.region.redirect_to = $('form#profile_region').find('input#redirect_to').val()
    params.region.region_resources_attributes = []
    params.region.trade_ids = $('form#profile_region').find('select#region_trade_ids').val()
    stateIds.forEach(function(stateId) {
      params.region.region_resources_attributes.push({resource_id: stateId, resource_type: 'State'})
    })
    countyIds.forEach(function(countyId) {
      params.region.region_resources_attributes.push({resource_id: countyId, resource_type: 'County'})
    })
    zipCodeIds.forEach(function(zipCodeId) {
      params.region.region_resources_attributes.push({resource_id: zipCodeId, resource_type: 'ZipCode'})
    })
    $.ajax({
      url: $('form#profile_region').attr('action'),
      type: $('form#profile_region').find("input[name='_method']").val() || 'POST',
      data: params,
      dataType: "script"
    })
  });
});

$(document).ready(function() {
  var sidenavOpen = false;
  var isNavLocked = false;
  var isFiltered = false;
  $(document).on('click', '#lockNavbar', function(){
    if($('#wrapper').hasClass('toggled')) {
      $('#page-content-wrapper').css('padding-left','210px');
      $('.fixed-filter-btn-top').css('margin-left','210px');
      $('#mySidenav').css({ 'position': 'fixed' });
      sidenavOpen = true;
    }
    else{
      $('#page-content-wrapper').css('padding-left','62px');
      $('.fixed-filter-btn-top').css('margin-left','0');
      $('#mySidenav').css({ 'position': 'absolute' });
      sidenavOpen = false;
    }
  });

  $(document).on('click', '.navbar-header span#lockNavbar i',function(){
    wrapper = $('body #wrapper')
    if(wrapper.hasClass('toggled')){
      wrapper.removeClass('toggled')
    }
    else{
      wrapper.addClass('toggled')
    }
  });

  $(document).on('keyup', 'input#company-selection', function(e) {
    e.preventDefault();
    var paramsData = $(this).val()
    if(paramsData) {
      $.ajax({
        url: "/companies/get_companies",
        type: 'GET',
        data: {q: paramsData}
      });
    }
  });

});
