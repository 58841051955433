$(document).on('click', 'a#dMessage', function(e){
  e.preventDefault();
  $.ajax({url: $(this).attr('href')})
})

function loadMessages(page, selector){
  replyForm = page == 1 ? true : false
  data = {page: page}
  if($(selector).data('offset')){
    data['offset'] = $(selector).data('offset')
  }
  $.ajax({
    url: $(selector).data('url'),
    data: data,
    dataType: 'JSON',
    success: function(response){
      if(response.length > 0){
        $(selector).prepend(HandlebarsTemplates['conversations/messages']({messages: response}))
        if(page == 1){
          $(selector).scrollTop($(selector)[0].scrollHeight);
        }
      } else {
        $(selector).data('endReached', true)
      }
    }
  })
}

function bindEndlessEventOnConversation(selector){
  $(selector).endlessScroll({
    fireOnce: false,
    fireDelay: false,
    loader: '<div class="loading"><div>',
    callback: function(fireSequence, pageSequence, scrollDirection){
      if(scrollDirection == 'prev'){
        loadMessages(Math.abs(pageSequence - 1), this)
      }
    },
    ceaseFireOnEmpty: false,
    ceaseFire: function(fireSequence, pageSequence, scrollDirection ){
      if($(this).data('endReached')){
        return true;
      } else {
        return false;
      }
    }
  });
}
