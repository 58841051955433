if(gon.zendesk_url && gon.zendesk_url.length) {
  function zendesk_web_widget(disableHelpCenter){
    window.zEmbed = window.zE = null;
    $('head iframe[src="javascript:false"]').remove();
    $('[__jx__id], embed#__zopnetworkswf').remove();
    window.$zopim = undefined;

    (function(url, host) {
      var queue = [],
          dom,
          doc,
          where,
          iframe = document.createElement('iframe'),
          iWin,
          iDoc;

      window.zEmbed = function() {
        queue.push(arguments);
      };

      window.zE = window.zE || window.zEmbed;
      window.zESettings = {
        webWidget: {
          helpCenter: {
            suppress: disableHelpCenter
          }
        }
      };

      iframe.src = 'javascript:false';
      iframe.title = ''; iframe.role='presentation';  // a11y
      (iframe.frameElement || iframe).style.cssText = 'display: none';
      where = document.getElementsByTagName('script');
      where = where[where.length - 1];
      where.parentNode.insertBefore(iframe, where);

      iWin = iframe.contentWindow;
      iDoc = iWin.document;

      try {
        doc = iDoc;
      } catch(e) {
        dom = document.domain;
        iframe.src='javascript:var d=document.open();d.domain="'+dom+'";void(0);';
        doc = iDoc;
      }
      doc.open()._l = function() {
        var js = this.createElement('script');
        if (dom) this.domain = dom;
        js.id = 'js-iframe-async';
        js.src = url;
        this.t = +new Date();
        this.zendeskHost = host;
        this.zEQueue = queue;
        this.body.appendChild(js);
      };
      doc.write('<body onload="document._l();">');
      doc.close();
    }('//assets.zendesk.com/embeddable_framework/main.js', gon.zendesk_url));
  }

  $(document).on("turbolinks:load", function() {
    if(document.getElementById("zendesk_unauthenticated_livechat")){
      zendesk_web_widget(true);
    } else {
      zendesk_web_widget(false);
    }
  });


  $(document).on("click", "#popup-zendesk-widget,#popup_zendesk_widget,#zendesk_unauthenticated_livechat,#helpdesk_popup_zendesk_on_chat", function(e) {
    e.preventDefault();
    zE.activate();
  });
}
