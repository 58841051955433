/* eslint no-console:0 */

import {getCookie} from '../helpers'

export default function getStandardContext({ extra = {} } = {}) {
  const { schema, data = {} } = { ...window.uc?.snowplowStandardContext };
  const sessionId = getCookie('session_id');
  return {
    schema,
    data: {
      ...data,
      source: `${document.body.dataset.application}-javascript`,
      grx_session_id: sessionId,
      extra: extra || data.extra,
    },
  };
}
